exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-astrology-tsx": () => import("./../../../src/pages/astrology.tsx" /* webpackChunkName: "component---src-pages-astrology-tsx" */),
  "component---src-pages-blogs-tsx": () => import("./../../../src/pages/blogs.tsx" /* webpackChunkName: "component---src-pages-blogs-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-faqs-tsx": () => import("./../../../src/pages/faqs.tsx" /* webpackChunkName: "component---src-pages-faqs-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-numerology-tsx": () => import("./../../../src/pages/numerology.tsx" /* webpackChunkName: "component---src-pages-numerology-tsx" */),
  "component---src-pages-plan-tsx": () => import("./../../../src/pages/plan.tsx" /* webpackChunkName: "component---src-pages-plan-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-tarot-tsx": () => import("./../../../src/pages/tarot.tsx" /* webpackChunkName: "component---src-pages-tarot-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-templates-blogs-tsx": () => import("./../../../src/templates/blogs.tsx" /* webpackChunkName: "component---src-templates-blogs-tsx" */),
  "component---src-templates-learn-tsx": () => import("./../../../src/templates/learn.tsx" /* webpackChunkName: "component---src-templates-learn-tsx" */)
}

